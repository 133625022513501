import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import Footer from '@components/footer'

export type LayoutProps = {
  children: React.ReactNode
  pageContext: GT.PageHelpers.PageContext
}

export default function Layout({ children }: LayoutProps): ReactElement {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={{
        paddingTop: 6,
        backgroundColor: 'background.main',
        minHeight: '100vh',
      }}
    >
      {children}
      <Footer />
    </Grid>
  )
}
