import React, { ReactElement } from 'react'

import { WrapPageElementNodeArgs } from 'gatsby'

import Layout from './../src/components/layout'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import theme from '../config/theme'

type WrapPageArgs = WrapPageElementNodeArgs & {
  props: {
    pageContext: GT.PageHelpers.PageContext
    location: Record<string, boolean>
  }
}

export function wrapPage({ element, props }: WrapPageArgs): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  )
}
