import { createTheme } from '@mui/material/styles'

import LatoRegularSrc from '../../static/font/Lato-Regular.ttf'
import LatoBoldSrc from '../../static/font/Lato-Bold.ttf'
import LatoThinSrc from '../../static/font/Lato-Thin.ttf'
import LunaSrc from '../../static/font/Luna.ttf'
import SigmarOneSrc from '../../static/font/SigmarOne-Regular.ttf'

import Palette from './palette'

// declare default theme
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1024,
      xl: 1440,
    },
  },
  palette: Palette,
  spacing: 4,
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          marginBottom: '32px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          fontDisplay: swap;
          src: url(${LatoRegularSrc}) format('truetype');
        }

        @font-face {
          font-family: 'LatoBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          fontDisplay: swap;
          src: url(${LatoBoldSrc}) format('truetype');
        }

        @font-face {
          font-family: 'LatoThin';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          fontDisplay: swap;
          src: url(${LatoThinSrc}) format('truetype');
        }

        @font-face {
          font-family: 'Luna';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          fontDisplay: swap;
          src: url(${LunaSrc}) format('truetype');
        }

        @font-face {
          font-family: 'SigmarOne';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          fontDisplay: swap;
          src: url(${SigmarOneSrc}) format('truetype');
        }
      `,
    },
  },
})

// customize theme by using default theme object. helpful, if you need theme for second level declaratios
export default createTheme(theme, {
  typography: {
    h1: {
      fontFamily: 'Luna, Lato, Helvetica Arial, sans-serif',
      fontWeight: 500,
      lineHeight: 1.2,
      fontSize: '32px',
      marginBottom: '32px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        marginBottom: '40px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '40px',
      },
    },
    h2: {
      fontFamily: 'Luna, Lato, Helvetica Arial, sans-serif',
      fontWeight: 500,
      lineHeight: 1.2,
      fontSize: '24px',
      marginBottom: '16px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        marginBottom: '24px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '38px',
        marginBottom: '24px',
      },
    },
    h3: {
      fontFamily: 'Lato, Helvetica Arial, sans-serif',
      fontWeight: 500,
      lineHeight: 1.3,
      fontSize: '18px',
      marginBottom: '16px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '22px',
        marginBottom: '24px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '28px',
      },
    },
    fontFamily: 'Lato, Helvetica Arial, sans-serif',
    fontFamily2: 'Luna, Lato, Helvetica Arial, sans-serif',
    subtitle1: {
      fontFamily: 'SigmarOne, Lato, Helvetica Arial, sans-serif',
      // fontSize: { sm: '20px', md: '24px', lg: '32px' },
      fontSize: '20px',
      lineHeight: 1.2,
      fontWeight: 400,
      margin: 0,
      marginBottom: 2,
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '32px',
      },
    },
  },
})
