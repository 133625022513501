import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Container, Grid } from '@mui/material'

export default function Footer(): React.ReactElement {
  const location = useLocation()

  return (
    <Container
      sx={{
        marginTop: { xs: 12, md: 18 },
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <Grid container justifyContent="flex-end" spacing={2}>
        {location.pathname !== '/' && (
          <Grid item>
            <Link to="/">Home</Link>
          </Grid>
        )}
        {location.pathname !== '/impressum' && (
          <Grid item>
            <Link to="/impressum">Impressum</Link>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
